<template>
    <div class="tools" id="tools">
        <div class="mobile-title">
            <p>כלים עסקיים</p>
        </div>
        <div class="apps-grid">
            <div class="app-box" id="catalog-app">
                <div class="app-status">
                    <div class="online">ONLINE</div>
                </div>
                <div class="app-tag">
                </div>
                <div class="app-image">
                    <img src="https://image.flaticon.com/icons/png/512/3486/3486510.png" alt="">
                </div>
                <div class="app-desc">
                    <div class="app-title">
                        קטלוג דיגיטלי
                    </div>
                    <p>⚬ צור קטלוג דיגיטלי ייחודי</p>
                    <p>⚬ שתף את הקטלוג עם הלקוחות שלך</p>
                    <p>⚬ הצג את הקטלוג בשטח</p>
                    <p>⚬ קבל טפסי התעניינות במוצרים</p>
                    <p>⚬ צפה באנליטיקות</p>
                </div>
                <div class="app-video">
                    <i class="el-icon-video-camera" style="margin-left:10px; font-size:2vh;"/>
                    <p>הדגמה</p>
                </div>
                <div class="app-launch" v-if="user">
                    <div class="install-app" v-if="user.profile && !user.profile.apps.includes('catalog')" @click="install_app('catalog')">
                        <i class="el-icon-upload" style="margin-left:10px; font-size:2vh;"/>
                        <p>התקן</p>
                    </div>
                    <div class="launch-app" v-if="user.profile && user.profile.apps.includes('catalog')" @click="launch_app('/apps/catalog')">
                        <i class="el-icon-video-play" style="margin-left:10px; font-size:2vh;"/>
                        <p>הפעל</p>
                    </div>
                </div>
                <div class="app-launch" v-if="!user">
                    <div class="log-in" @click="open_user_widget">
                        <i class="el-icon-user" style="margin-left:10px; font-size:2vh;"/>
                        <p>התחבר</p>
                    </div>
                </div>
            </div>
            <div class="app-box" id="prices-app">
                <div class="app-status">
                    <div class="online">ONLINE</div>
                </div>
                <div class="app-tag">
                </div>
                <div class="app-image">
                    <img src="https://image.flaticon.com/icons/png/512/3462/3462067.png" alt="">
                </div>
                <div class="app-desc">
                    <div class="app-title">
                        השוואת מחירים
                    </div>
                    <p>⚬ צור קבוצות פריטים להשוואה</p>
                    <p>⚬ השווה מחירים אל מול המתחרים</p>
                    <p>⚬ בדוק הצעות מחיר בלחיצת כפתור</p>
                    <p>⚬ ייצא דוחות השוואה</p>
                </div>
                <div class="app-video">
                    <i class="el-icon-video-camera" style="margin-left:10px; font-size:2vh;"/>
                    <p>הדגמה</p>
                </div>
                <div class="app-launch" v-if="user">
                    <div class="install-app" v-if="user.profile && !user.profile.apps.includes('prices')" @click="install_app('prices')">
                        <i class="el-icon-upload" style="margin-left:10px; font-size:2vh;"/>
                        <p>התקן</p>
                    </div>
                    <div class="launch-app" v-if="user.profile && user.profile.apps.includes('prices')" @click="launch_app('/apps/prices')">
                        <i class="el-icon-video-play" style="margin-left:10px; font-size:2vh;"/>
                        <p>הפעל</p>
                    </div>
                </div>
                <div class="app-launch" v-if="!user">
                    <div class="log-in">
                        <i class="el-icon-user" style="margin-left:10px; font-size:2vh;"/>
                        <p>התחבר</p>
                    </div>
                </div>
            </div>
            <div class="app-box" id="shilut-app">
                <div class="app-status">
                    <div class="online">ONLINE</div>
                </div>
                <div class="app-tag">
                </div>
                <div class="app-image">
                    <img src="https://image.flaticon.com/icons/png/512/2659/2659360.png" alt="">
                </div>
                <div class="app-desc">
                    <div class="app-title">
                        מערכת שילוט
                    </div>
                    <p>⚬ צור תבניות שילוט בהתאמה אישית</p>
                    <p>⚬ טען פריטים בלחיצת כפתור</p>
                    <p>⚬ הדפס שלטים במהירות</p>
                    <p>⚬ שתף את קבצי השילוט</p>
                    <p>⚬ מתג והתאם את השילוט במהירות</p>
                </div>
                <div class="app-video">
                    <i class="el-icon-video-camera" style="margin-left:10px; font-size:2vh;"/>
                    <p>הדגמה</p>
                </div>
                <div class="app-launch" v-if="user">
                    <div class="install-app" v-if="user.profile && !user.profile.apps.includes('shilut')" @click="install_app('shilut')">
                        <i class="el-icon-upload" style="margin-left:10px; font-size:2vh;"/>
                        <p>התקן</p>
                    </div>
                    <div class="launch-app" v-if="user.profile && user.profile.apps.includes('shilut')">
                        <i class="el-icon-video-play" style="margin-left:10px; font-size:2vh;"/>
                        <p>הפעל</p>
                    </div>
                </div>
                <div class="app-launch" v-if="!user">
                    <div class="log-in">
                        <i class="el-icon-user" style="margin-left:10px; font-size:2vh;"/>
                        <p>התחבר</p>
                    </div>
                </div>
            </div>
            <div class="app-box" id="soon-app">
                <div class="app-status">
                </div>
                <div class="app-tag">
                </div>
                <div class="app-image">
                    <img src="https://image.flaticon.com/icons/png/512/4771/4771341.png" alt="">
                </div>
                <div class="app-desc">
                    <div class="app-title">
                        בקרוב
                    </div>
                    <p class="soon-app">פתרונות וכלים נוספים נמצאים בפיתוח ובדיקה ממש ברגעים אלו</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import store from '../store';
import { FireStore, projectAuth, projectFirestore } from '../firebase/config';
import { ElNotification } from "element-plus";
import router from '../router';

export default {
components:{},
setup(){
    const show_video = ref(false);
    const video_ref = ref(null);

    const user = ref(computed(() => {
        return store.getters.user
    }));

    const open_user_widget = () => {
        store.dispatch('toggle_user_widget');
    }

    const install_app = async (app_name) => {
        if(user.value.profile && !user.value.profile.apps.includes(app_name)){
            if(app_name == 'prices'){
                await projectFirestore.collection('Users').doc(projectAuth.currentUser.uid).update({
                    apps: FireStore.FieldValue.arrayUnion(app_name)
                })
                slide_pop_successs('האפליקצייה הותקנה בהצלחה בענן שלך');
                store.dispatch('getProfile');
            }else{
                slide_pop_info('האפליקצייה נמצאת בשלבי בדיקה, נעדכן בקרוב.')
            }
        }
    }

    const slide_pop_info = (text) => {
        ElNotification({
            title: "הודעה",
            type: "info",
            message: text,
            showClose: false,
            duration: 2500
        });
    }

    const slide_pop_successs = (text) => {
        ElNotification({
            title: "הצלחה",
            type: "success",
            message: text,
            showClose: false,
            duration: 2000
        });
    }

    const launch_app = (app_path) =>{
        router.push(app_path)
    }

    onMounted(() => {
        const tools = document.getElementById('tools');
        if(tools){
            tools.classList.toggle('fade-in')
        }
    })
    
    return{
        show_video, video_ref, user,
        open_user_widget, install_app, launch_app,
    }
}
}
</script>

<style scoped>
.mobile-title{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: 70px;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    display: none;
    z-index: 1;
    font-size: 4vh;
}
.tools{
    position: relative;
    width: calc(100%);
    height: calc(100%);
    padding: 65px;
    background: var(--bg-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.apps-grid{
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-rows: 390px;
    grid-template-columns: calc(25% - 10px) calc(25% - 10px) calc(25% - 10px) calc(25% - 10px);
    overflow-y: auto;
    gap: 10px;
    justify-content: center;
}
.app-box{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    transition: 0.5s ease-in;
    display: grid;
    color: whitesmoke;
    grid-template-areas:
    "app-tag    app-status"
    "app-image  app-image"
    "app-desc   app-desc"
    "app-video  app-launch";
    grid-template-columns: 50% 50%;
    grid-template-rows: 10% 25% 50% 15%;
}
.app-status{
    grid-area: app-status;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-tag{
    grid-area: app-tag;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
}
.app-image{
    grid-area: app-image;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-image img{
    max-width: 100%;
    max-height: 100%;
}
.app-desc{
    grid-area: app-desc;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 18px;
        text-shadow: 1px 1px 3px #0000008a;

}
.app-title{
    width: 100%;
    text-align: center;
    font-size: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    color: white;
    text-shadow: 1px 1px 2px #0000008a;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}
.app-launch{
    grid-area: app-launch;
    width: 100%;
    height: 100%;
}
.app-video{
    grid-area: app-video;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 2.5vh; 
    color: rgb(128, 233, 193);
}
.app-video:hover{
    background: linear-gradient(#00545d91, #0007297a);
    cursor: pointer;
}
.app-launch:hover{
    background: linear-gradient(#00545d91, #0007297a);
    cursor: pointer;
}
.app-box .content{
    padding: 10px;
}
.online{
    width: 100%;
    text-align: left;
    padding-left: 10px;
    color: lawngreen;
    text-shadow:0 0 3px lawngreen;
    letter-spacing: 1px;
}
.offline{
    width: 100%;
    text-align: left;
    color: rgb(241, 40, 40);
    text-shadow:0 0 3px rgb(241, 40, 40);
    letter-spacing: 1px;
    padding-left: 10px;

}
.app-box:hover{
    background: linear-gradient(to left top, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.install-app{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 2.5vh; 
    color: rgb(128, 233, 193);
}
.launch-app{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 2.5vh; 
    color: rgb(128, 233, 193);
}
.log-in{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 2.5vh; 
    color: rgb(128, 233, 193);
}
.soon-app{
    text-align: center;
    width: 100%;
    font-size: 2.5vh;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@media only screen and (max-width: 1004px) {
    .mobile-title{
        display: flex;
    }
    .tools{
        padding: 70px 10px 10px 10px;
    }
    .apps-grid{
        width: 100%;
        height: 100%;
        display: grid;
        grid-auto-rows: 390px;
        grid-template-columns: 100%;
        overflow-y: auto;
        gap: 10px;
        justify-content: center;
    }
    .app-box{
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    }
}
</style>